import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';
import { MysqlService } from '../../services/mysql.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  user:any;

  constructor( private fb: FirebaseService, private ws: MysqlService, private router: Router ) {
    //console.log('User: ', this.fb.user);
    this.user = this.fb.user;
  }

  ngOnInit() {
  }

  async isAdmin(){
    const admins = this.ws.admins;
    
    if(this.user){
      let index = admins.indexOf(this.user.email);
      return index > -1 ? true : false;
    }

    return false;
  }

  logOut(){
    this.fb.setLogout().then(() => {
      this.router.navigate(['/']);
    });
  }

}
