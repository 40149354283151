import { Injectable, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NotificationBarService, NotificationType } from 'ngx-notification-bar';

@Injectable({
  providedIn: "root"
})
export class MysqlService implements OnInit{
  //ws: string = "http://localhost/evokeinternational/index.php/ajax/";
  ws: string = "https://api.evokeinternational.com.mx/index.php/Ajax/"; //Prod
  
  unidades = ["Departamento", "Townhouse", "Casa", "Locales", "Estudio", "Lotes"];
  monedas = ["MXN", "USD"];
  vinculos = ['web', 'facebook', 'instagram', 'inmuebles24', 'whatsapp', 'click_funnels', 'mailchimp', 'manualmente', 'google', 'landing_page', 'embajador', 'broker', 'agencia'];
  status = ['nuevo', 'primer_contacto', 'sin_respuesta', 'interesado', 'visita_programada', 'stand_by', 'oferta', 'cerrada', 'warm', 'cold'];
  idiomas = [
    {
      id:'spa',
      name:'Español'
    },
    {
      id:'eng',
      name:'Inglés'
    },
    {
      id:'fra',
      name:'Francés'
    },
    {
      id:'ita',
      name:'Italiano'
    }
  ];

  admins = ['mkt@evokerealestategroup.com', 'josi@evokerealestategroup.com', 'admin@evokerealestategroup.com', 'alejandro.valadez.romo@gmail.com', 'edson@evokerealestategroup.com'];
  asesores:Array<string> = [
    'josi@evokerealestategroup.com',
    'mel@evokerealestategroup.com',
    'jorge@evokerealestategroup.com',
    'admin@evokerealestategroup.com',
    'mkt@evokerealestategroup.com',
    'marlen@evokerealestategroup.com',
    'edson@evokerealestategroup.com',
    'alejandro.valadez.romo@gmail.com',
    'andres@evokerealestategroup.com',
  ];

  dolar = 19.65;
  clientes:any = [];
  httpHeaders:any;

  constructor(private http: HttpClient, private notificationBarService: NotificationBarService) {
    this.getStatus();
    this.httpHeaders = new HttpHeaders();

    this.httpHeaders.append('Access-Control-Allow-Headers', 'Cache-Control, Content-Type, Accept-Encoding, Content-Length')
    this.httpHeaders.append('Cache-Control', 'no-cache')
    this.httpHeaders.append('Content-Type', 'application/json; charset=utf-8');
  }

  ngOnInit(){}

  getStatus() {
    this.http.get(this.ws + 'obtener_estados').subscribe( (response: {data: Array<string>}) => {
      if (response) {
        this.status = response.data;
      }
    });
  }

  /** Tools */
  notify(message:string, type?:string, delay?:number){
    const duration = delay ? delay : 3000;
    if(type == 'success'){
      this.notificationBarService.create({ message: message, type: NotificationType.Success, hideDelay: duration});
    } else if( type == 'error'){
      this.notificationBarService.create({ message: message, type: NotificationType.Error, hideDelay: duration});
    } else if( type == 'warning'){
      this.notificationBarService.create({ message: message, type: NotificationType.Warning, hideDelay: duration});
    } else{
      this.notificationBarService.create({ message: message, type: NotificationType.Info, hideDelay: duration});
    }
    
  }

  /** BLOG */
  postBlog(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "crear_post", params);
  }

  getBlog(limit?) {
    return this.http.get(this.ws + "obtener_posts/" + limit);
  }

  updatePost(id, vars) {
    const params = JSON.stringify({ id: id, data: vars });
    return this.http.post(this.ws + "editar_post", params);
  }

  /** Lugares */
  getLugares() {
    return this.http.get(this.ws + "obtener_lugares");
  }

  /** Desarrolladores */
  createDesarrollador(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "crear_desarrollador", params);
  }

  getDesarrolladores(id?) {
    if (id) {
      return this.http.get(this.ws + "obtener_desarrolladores/" + id);
    } else {
      return this.http.get(this.ws + "obtener_desarrolladores");
    }
  }

  /** Proyectos */
  createProyecto(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "crear_proyecto", params);
  }

  /** Propiedades */
  createPropiedad(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "crear_propiedad", params);
  }

  updatePropiedad(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "editar_propiedad", params);
  }

  /** Precios */
  createPrice(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "crear_precio", params);
  }

  /** Clientes */
  getClientes(email:string, limit?:number){
    const getString = limit ? '?limit=' + limit : '';
    return this.http.get(this.ws + 'obtener_clientes/' + email + getString);
  }

  getClientesAdmin(email:string, limit?:number){
    const getString = limit ? '?limit=' + limit : '';
    return this.http.get(this.ws + 'obtener_clientes_admin/' + email + getString);
  }

  getRecents(email:string){    
    return this.http.get(this.ws + 'obtener_clientes_recientes/' + email);
  }

  getDatabase(email:string){
    return this.http.get(this.ws + 'obtener_clientes_database/' + email);
  }

  createCliente(vars) {
    const params = JSON.stringify({cliente: vars});
    return this.http.post(this.ws + "crear_lead", params);
  }

  updateCliente(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "editar_cliente", params);
  }

  asignarCliente(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "asignar_cliente", params);
  }

  deleteCliente(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "eliminar_cliente", params);
  }

  removeCliente(vars){
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "delete_cliente", params);
  }

  seguimientoCliente(id: Number, vars) {
    const params = JSON.stringify(vars);
    return this.http.patch(this.ws + "seguimiento_cliente/" + id, params);
  }

  createBitacora(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "crear_bitacora", params);
  }

  createNota(vars) {
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + "crear_nota", params);
  }

  getNotas(id:number){
    return this.http.get(this.ws + 'obtener_notas/' + id);
  }

  /** Todo List */
  getTodoList(email, fecha?, id?:number){
    const idbitacora = id ? id : '';
    const params = JSON.stringify({email:email, fecha:fecha, idbitacora:idbitacora});
    return this.http.post(this.ws + 'obtener_bitacora', params);
  }

  updateTask(id:number, vars:any){
    const params = JSON.stringify({idbitacora:id, vars:vars});
    return this.http.post(this.ws + 'editar_bitacora', params);
  }

  //Dolar
  getExchange(base?:string){
    base = base ? base : 'USD';
    return this.http.get('https://api.exchangeratesapi.io/latest?base='+base);
  }

  setDataTables() {
    $(".dataTables").DataTable().destroy();
    setTimeout(() => {
      $(".dataTables").DataTable({
        order: [],
        language: {
          lengthMenu: "Mostrar _MENU_ registros por página",
          zeroRecords: "No hay resultados",
          info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
          infoEmpty: "No hay resultados",
          infoFiltered: "(filtrados de _MAX_ registros totales)",
          processing: "Procesando...",
          search: "Buscar:",
          paginate: {
            first: "Primera",
            last: "Última",
            next: "Siguiente",
            previous: "Anterior"
          },
          aria: {
            sortAscending: ": activar orden ascendente",
            sortDescending: ": activar orden descendente"
          }
        }
      });
    }, 800);
  }

  getLeadsProyecto(vars?){
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + 'obtener_reporte_leads', params);
  }

  getReporteLeadsAjax( vars? ){
    const params = JSON.stringify(vars);
    return this.http.post(this.ws + 'reporte_leads_ajax', params);
  }

  /** Templates */
  getTemplates(id?:number){
    const url = id ? this.http.get(this.ws + 'obtener_templates/'+id) : this.http.get(this.ws + 'obtener_templates');
    return url;
  }

  updateTemplate(id:number, vars){
    const params = JSON.stringify({id:id, vars:vars});
    return this.http.post(this.ws + 'editar_template', params);
  }
}
