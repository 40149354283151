import { Component, OnInit } from "@angular/core";
import { FirebaseService } from "src/app/services/firebase.service";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-delete-propiedad",
  templateUrl: "./delete-propiedad.component.html",
  styleUrls: ["./delete-propiedad.component.css"]
})
export class DeletePropiedadComponent implements OnInit {
  id: string;
  index: number;
  desarrollador: any = {};
  propiedad: any = {};

  constructor(
    private ws: FirebaseService,
    private router: Router,
    private routes: ActivatedRoute
  ) {}

  ngOnInit() {
    this.routes.params.subscribe((params: Params) => {
      this.id = params.id;
      this.index = params.index;
      this.getData();
    });
  }

  getData() {
    const des = this.ws.getDesarrolladores();
    let mainDes: any = {};

    for (const a of des) {
      if (a.id == this.id) {
        mainDes = a;
        const prop = a.data().propiedades;
        this.propiedad = prop[this.index];
      }
    }

    this.desarrollador = mainDes;
  }

  deletePropiedad() {
    //console.log(this.id);
    if (this.propiedad) {
      let propiedades = this.desarrollador.data().propiedades;
      propiedades.splice(this.index, 1);
      this.ws
        .updateDesarrollador(this.id, { propiedades: propiedades })
        .then(res => {
          this.ws.desarrolladores = [];
          this.ws.cargarDesarrolladores().then(res => {
            res.forEach(doc => {
              this.ws.desarrolladores.push(doc);
            });

            this.router.navigate([
              "/desarrolladores",
              this.desarrollador.id,
              "propiedades"
            ]);
          });
        })
        .catch(err => {
          console.log("Error ", err);
        });
    }
  }
}
