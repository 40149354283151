import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MysqlService } from "src/app/services/mysql.service";

import { ImageCroppedEvent } from "ngx-image-cropper";
import { FirebaseService } from "src/app/services/firebase.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-create-post",
  templateUrl: "./create-post.component.html",
  styleUrls: ["./create-post.component.css"]
})
export class CreatePostComponent implements OnInit {
  form: FormGroup;
  htmlContent: string;
  imageSrc: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "25rem",
    minHeight: "5rem",
    placeholder: "Escribe aquí cualquier texto...",
    translate: "no",
    uploadUrl: "v1/images", // if needed
    customClasses: [
      // optional
      {
        name: "quote",
        class: "quote"
      },
      {
        name: "redText",
        class: "redText"
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1"
      }
    ]
  };

  constructor(
    private router: Router,
    private ws: MysqlService,
    private ws2: FirebaseService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      titulo: new FormControl("", Validators.required),
      link: new FormControl("", Validators.required),
      descripcion: new FormControl("", Validators.required),
      keywords: new FormControl("", Validators.required)
    });
  }

  shortLink() {
    const rep = this.form.value.titulo.replace(/ /g, "-");
    this.form.patchValue({ link: rep });
  }

  async submit() {
    if (this.form.valid && this.imageSrc) {
      console.log("Value form: ", this.form.value);
      await this.uploadImage()
        .then(url => {
          let formValues = this.form.value;
          formValues.portada = url;
          this.ws.postBlog(formValues).subscribe(response => {
            if (response != "fail") {
              console.log("Success: ", response);
              this.router.navigate(["/blog"]);
            }
          });
        })
        .catch(err => console.log("Error: ", err));
    }
  }

  uploadImage() {
    return new Promise((resolve, revoke) => {
      const rand = Math.floor(Math.random() * 100 + 1);
      let vars = this.form.value;
      let uploadTastk = this.ws2.uploadPortadaBlog(
        btoa(vars.link + rand),
        this.imageSrc
      );
      uploadTastk.on(
        "state_changed",
        function(snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          /*switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
          }*/
        },
        function(error) {
          // Handle unsuccessful uploads
          revoke(error);
        },
        function() {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...

          uploadTastk.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            resolve(downloadURL);
          });
        }
      );
    });
  }

  /** Image controller */
  imageChangedEvent: any = "";
  croppedImage: any = "";

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imageSrc = null;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log("change position:", event);
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  cancelarCropper() {
    this.imageChangedEvent = false;
    this.croppedImage = null;
  }

  getCropped() {
    this.imageSrc = this.croppedImage;
    this.cancelarCropper();
    console.log("Image Data:", this.imageSrc);
  }
}
