import { Component, OnInit } from '@angular/core';
import { MysqlService } from '../../services/mysql.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-bitacora-cliente',
  templateUrl: './bitacora-cliente.component.html',
  styleUrls: ['./bitacora-cliente.component.css']
})
export class BitacoraClienteComponent implements OnInit {
  cliente: {idcliente:number, nombre:string, bitacora:any};
  bitacora = [];
  form: FormGroup;

  constructor( private fb: FirebaseService, private mysql: MysqlService, 
    private router: Router, private routes: ActivatedRoute ) {
    this.fb.isLogged();
    
  }

  async ngOnInit() {
    this.initForm();
    await this.mysql.getClientes(this.fb.auth.currentUser.email);
    await this.routes.params.subscribe((params:Params) => {      
      if(params.id && this.mysql.clientes.length > 0){
        const clientes = this.mysql.clientes;
        for(let i = 0; i < clientes.length; i++){
          if(clientes[i].idcliente == params.id){
            this.cliente = clientes[i];
          }
        }

        this.bitacora = this.cliente.bitacora.length > 0 ? this.cliente.bitacora : [];
        console.log('Cliente: ', this.cliente);
      }
    });
  }

  initForm(){
    this.form = new FormGroup({
      fecha: new FormControl("", Validators.required),
      observacion: new FormControl("", [Validators.required, Validators.maxLength(1000)])
    })
  }

  submit(){
    if(this.form.valid){
      let params = this.form.value;
      params.idcliente = this.cliente.idcliente;
      this.mysql.createBitacora(params).subscribe( (response:string) => {
        
        if(response == 'success'){
          console.log('Response:', response);
          this.router.navigate(['/clientes']);
        }
      });
    }
  }
}
