import { Component, OnInit } from "@angular/core";
import { MysqlService } from "src/app/services/mysql.service";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-proyectos",
  templateUrl: "./proyectos.component.html",
  styleUrls: ["./proyectos.component.css"]
})
export class ProyectosComponent implements OnInit {
  id: number;
  proyectos: any = [];

  constructor(private ws: MysqlService, private routes: ActivatedRoute) {}

  ngOnInit() {
    this.routes.params.subscribe((params: Params) => {
      this.id = params.id;
      this.obtenerProyectos(params.id);
    });
  }

  obtenerProyectos(id) {
    this.ws.getDesarrolladores(id).subscribe(res => {
      if (res != "fail") {
        const desarrollador = res[0];
        this.proyectos = res != "fail" ? desarrollador.proyectos : [];
        console.log("Proyects: ", this.proyectos);
      }
    });
  }
}
