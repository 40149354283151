import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-proyectos',
  templateUrl: './home-proyectos.component.html',
  styleUrls: ['./home-proyectos.component.css']
})
export class HomeProyectosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
