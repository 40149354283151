import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';

declare var $:any;

@Component({
  selector: 'app-table-to-excel',
  templateUrl: './table-to-excel.component.html',
  styleUrls: ['./table-to-excel.component.css']
})
export class TableToExcelComponent implements OnInit, OnChanges {
  @Input() leads: any = [];
  @Input() onDoExport = new Subject();
  @Output() onExport = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.onDoExport.subscribe( (res: boolean) => {
      if( res == true ){
        if( this.leads && this.leads.length > 0 ){
          //console.log('Hay leads: ', this.leads, 'Res: ', res);
          this.exportList();
        }
      }      
    });
  }

  exportList(){
    $("#tablaFiltrada").table2excel({
      exclude: ".excludeThisClass",
      name: "leads",
      filename: "Leads_Filtrados.xls", // do include extension
      preserveColors: false // set to true if you want background colors and font colors preserved
    });
  }

}
