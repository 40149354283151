import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MysqlService } from "src/app/services/mysql.service";

@Component({
  selector: "app-create-propiedad",
  templateUrl: "./create-propiedad.component.html",
  styleUrls: ["./create-propiedad.component.css"]
})
export class CreatePropiedadComponent implements OnInit {
  index: string = "";
  id: string = "";
  title = "Nueva Propiedad";
  propiedades: any = [];
  tipos_unidades = [];

  form: FormGroup;
  constructor(
    private ws: MysqlService,
    private routes: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();

    this.routes.params.subscribe((params: Params) => {
      this.index = params.id;
      this.id = params.idp;
      //this.obtenerProyectos(params.idp);
    });

    this.tipos_unidades = this.ws.unidades;
  }

  initForm() {
    this.form = new FormGroup({
      tipo_unidad: new FormControl("", Validators.required),
      recamaras: new FormControl("", Validators.required),
      banos: new FormControl(""),
      fecha_entrega: new FormControl("", [Validators.required]),
      m2: new FormControl("", [Validators.required, Validators.min(0)]),
      observaciones: new FormControl(""),
      status: new FormControl("", Validators.required),
      drive: new FormControl(""), portada: new FormControl("")
    });
  }

  submit() {
    if (this.form.valid) {
      console.log("Send: ", this.form.valid);
      let vars = this.form.value;
      vars.idproyecto = this.id;

      this.ws.createPropiedad(vars).subscribe(res => {
        if (res != "fail") {
          this.router.navigate([
            "/desarrolladores",
            this.index,
            "proyectos",
            this.id,
            "propiedades"
          ]);
        }
      });
    }
  }
}
