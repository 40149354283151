import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { MysqlService } from "src/app/services/mysql.service";

@Component({
  selector: "app-propiedades",
  templateUrl: "./propiedades.component.html",
  styleUrls: ["./propiedades.component.css"]
})
export class PropiedadesComponent implements OnInit {
  title: string = "Propiedades";
  id: number;
  id2: number;
  propiedades: any = [];

  constructor(private ws: MysqlService, private routes: ActivatedRoute) {}

  setDataTables() {
    setTimeout(() => {
      $(".dataTables").DataTable({
        language: {
          lengthMenu: "Mostrar _MENU_ registros por página",
          zeroRecords: "No hay resultados",
          info: "Mostrando página _PAGE_ de _PAGES_",
          infoEmpty: "No hay resultados",
          infoFiltered: "(filtrados de _MAX_ registros totales)",
          processing: "Procesando...",
          search: "Buscar:",
          paginate: {
            first: "Primera",
            last: "Última",
            next: "Siguiente",
            previous: "Anterior"
          },
          aria: {
            sortAscending: ": activar orden ascendente",
            sortDescending: ": activar orden descendente"
          }
        }
      });
    }, 1500);
  }

  ngOnInit() {
    this.routes.params.subscribe((params: Params) => {
      //this.setDataTables();
      console.log("Params:", params);
      this.id = params.id;
      this.id2 = params.idp;
      this.obtenerProyectos(params.id);
    });
  }

  obtenerProyectos(id) {
    this.ws.getDesarrolladores(id).subscribe(res => {
      if (res != "fail") {
        const desarrollador = res[0];
        const proyectos = res != "fail" ? desarrollador.proyectos : [];
        if (proyectos) {
          this.propiedades = this.obtenerPropiedades(proyectos, this.id2);
        }
      }
    });
  }

  obtenerPropiedades(proyectos, id) {
    for (const a of proyectos) {
      if (a.idproyecto == id) {
        return a.propiedades;
      }
    }

    return [];
  }
}
