import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from '../../../node_modules/rxjs';
import { timeout } from 'rxjs/operators';

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        const headers = {
            //'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Headers': 'Cache-Control',
            //'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            'Content-Type': 'application/json;charset=utf-8',
            //'Pragma': 'no-cache',
            'Authorization': 'QjQ4OXNiZ3Jpb3V3aXVyYmYyMDIwMDQxMjE1MjU0M2l1d3V5cTk4cj89P2lnMzg5MjY='
        };

        //console.log('Usar headers: ', headers);
        const authReq = req.clone({
            //Prevent caching in IE, in particular IE11
            setHeaders: headers
        });

        return next.handle(authReq).pipe(timeout(5000));
    }
}