import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MysqlService } from 'src/app/services/mysql.service';

@Component({
  selector: 'app-modal-bitacora-consulta',
  templateUrl: './modal-bitacora-consulta.component.html',
  styleUrls: ['./modal-bitacora-consulta.component.css']
})
export class ModalBitacoraConsultaComponent implements OnInit {
  @Input() cliente;
  @Output() onCreate = new EventEmitter<any>();
  form: FormGroup;
  
  constructor( private mysql: MysqlService ) { }

  ngOnInit() {
  }

  ngOnChanges(){
    console.log('Selected: ', this.cliente);
    this.initForm();
    if( this.cliente ){
      this.cliente.bitacora = !this.cliente.bitacora ? [] : this.cliente.bitacora;
      const nota = {
        nota: this.cliente.observaciones,
        fecha: this.cliente.alta,
        asesor: this.cliente.asesor
      };

      this.cliente.notas.push(nota);
    }
  }

  initForm(){
    this.form = new FormGroup({
      fecha: new FormControl("", Validators.required),
      observacion: new FormControl("", [Validators.required, Validators.maxLength(1000)])
    })
  }

  submit(){
    if(this.form.valid){
      let params = this.form.value;
      params.idcliente = this.cliente.idcliente;
      this.mysql.createBitacora(params).subscribe( (response:string) => {
        if(response == 'success'){
          const newTask = this.form.value;
          this.cliente.bitacora.unshift(newTask);
          this.form.reset();
          this.onCreate.emit({isUpdated: true, cliente: this.cliente});
        }
      });
    }
  }


}
