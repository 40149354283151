import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MysqlService } from '../../services/mysql.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-update-cliente',
  templateUrl: './update-cliente.component.html',
  styleUrls: ['./update-cliente.component.css']
})
export class UpdateClienteComponent implements OnInit {
  id:number;
  cliente:any;
  form:FormGroup;
  form2:FormGroup;

  vinculos:any;
  status:any;
  idiomas:any;

  user:any;
  asesores:any = [];
  notas:Array<any> = [];

  constructor( private routes: ActivatedRoute, private ws: MysqlService, private router: Router, private fb: FirebaseService ) {
    this.fb.isLogged();
    this.vinculos = this.ws.vinculos;
    this.status = this.ws.status;
    this.idiomas = this.ws.idiomas;
    this.user = this.fb.user;
    this.asesores = this.ws.asesores;
  }

  ngOnInit() {
    this.initForm();
    this.cliente = JSON.parse(localStorage.getItem("cliente"));
    this.cliente.notas = !this.cliente.notas ? [] : this.cliente.notas;
    const nota = {
      nota: this.cliente.observaciones,
      fecha: this.cliente.alta,
      asesor: this.cliente.asesor
    };

    this.cliente.notas.push(nota);
    this.patchForm(this.cliente);

    this.routes.params.subscribe((params: Params) => {
      this.id = params.id;
      this.notas = this.cliente.notas;
    });
  }

  initForm(){
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      status: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      telefono: new FormControl(""),
      observaciones: new FormControl(""),
      idioma: new FormControl("", Validators.required)
    });

    this.form2 = new FormGroup({
      asesor: new FormControl("", Validators.required)
    });
  }

  patchForm(cliente){
    this.form.patchValue({
      nombre: cliente.nombre,
      status: cliente.status,
      email: cliente.email,
      telefono: cliente.telefono,
      inversion: cliente.inversion,
      observaciones: cliente.observaciones,
      vinculo: cliente.vinculo,
      propiedad_interes: cliente.propiedad_interes,
      idioma: cliente.idioma
    });

    this.form2.patchValue({
      asesor: cliente.asesor
    })
  }

  submit(){
    let vars = {id:this.id, cliente: this.form.value};
    
    this.ws.updateCliente(vars).subscribe(res => {
      if(res != 'fail'){
        localStorage.removeItem('cliente');
        this.router.navigate(['/clientes']);
      } else{
        console.log('Error de edición: ', res);
      }
    })
  }

  asignar(){
    let vars = {
      id:this.id, 
      cliente: {
        asesor: this.form2.value.asesor,
        nombre: this.cliente.nombre,
        email: this.cliente.email,
        telefono: this.cliente.telefono,
        status: this.cliente.status,
        tags: this.cliente.tags,
        alta: this.cliente.alta,
        propiedad_interes: this.cliente.propiedad_interes
      }
    };
    
    this.ws.asignarCliente(vars).subscribe(res => {
      if(res != 'fail'){
        this.router.navigate(['/clientes', 'recents']);
      } else{
        console.log('Error de edición: ', res);
      }
    })
  }

  isAdmin(email:string){
    const index = this.ws.admins.indexOf(email);
    if(index != -1){
      return 1;
    }

    return 0;
  }

  creaNota(data){
    this.cliente.notas.unshift(data);
  }
}
