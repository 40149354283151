import { Component, OnInit, EventEmitter } from '@angular/core';
import { MysqlService } from '../../services/mysql.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-home-clientes',
  templateUrl: './home-clientes.component.html',
  styleUrls: ['./home-clientes.component.css']
})
export class HomeClientesComponent implements OnInit {
  logged:any = {};
  clientes:any = [];
  admins = [];
  activo:any = false;
  limit:number = 30;  //inicialmente solo trae los últimos 30
  selectedCliente: any;
  doExport = new EventEmitter<boolean>();

  constructor( private ws: MysqlService, private fb: FirebaseService, private router: Router ) {
    this.fb.isLogged();
    this.admins = this.ws.admins;
  }

  setDataTables() {
    setTimeout(() => {
      $(".dataTables").DataTable({
        language: {
          lengthMenu: "Mostrar _MENU_ registros por página",
          zeroRecords: "No hay resultados",
          info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
          infoEmpty: "No hay resultados",
          infoFiltered: "(filtrados de _MAX_ registros totales)",
          processing: "Procesando...",
          search: "Buscar:",
          paginate: {
            first: "Primera",
            last: "Última",
            next: "Siguiente",
            previous: "Anterior"
          },
          aria: {
            sortAscending: ": activar orden ascendente",
            sortDescending: ": activar orden descendente"
          }
        }
      });
    }, 1500);
  }

  ngOnInit() {
    this.fb.isLogged().then( (response:any) => {
      this.logged = response && response.email ? response : null;
      this.cargarClientes();
    });
  } 

  verifyUser(){
    if(this.logged.email && this.admins.indexOf(this.logged.email) != -1){
      //console.log('Is: ', this.logged.email);
      return 1;
    } else{
      return 0;    
    }
  }

  verCliente(cliente){
    this.activo = cliente;
  }

  cerrarActivo(){
    this.activo = false;
    this.setDataTables();
  }

  cargarClientes(){
    this.ws.notify('Cargando clientes...');
    $(".dataTables").DataTable().destroy();
    this.ws.getClientes(this.fb.user.email, this.limit).subscribe(res => {
      if(res != 'fail'){
        this.clientes = res;
        this.ws.clientes = res;
      } else{
        this.clientes = [];
        this.ws.clientes = [];
      }
      this.setDataTables();
    }, e => {
      this.ws.notify('El servidor no responde... Intentando reconectar...', 'warning', 3000);
      this.cargarClientes();
    });
  }

  editarCliente(cliente){
    this.selectedCliente = cliente;
  }

  verSeguimiento(c){
    this.selectedCliente = c;
  }

  verBitacora(c){
    this.selectedCliente = c;
  }

  onClose(ev){
    console.log('On close: ', ev);
  }

  exportarLeads(){
    this.ws.notify("Espera mientras se exportan los resultados", "info", 5000);
    this.doExport.emit(true);
  }

  onUpdated(ev: {isUpdated:boolean; cliente:any}){
    if( ev.isUpdated ){ //Cliente actualizado....
      //Actualiza cliente sin actualizar la tabla...
      for(let i = 0; i < this.clientes.length; i++){
        if( this.clientes[i].idcliente == ev.cliente.idcliente ){
          this.clientes[i] = ev.cliente;
        }
      }
    }
  }

  onCreatedTask(ev: {isCreated:boolean; cliente:any}){
    if( ev.isCreated ){ //Cliente actualizado....
      //Actualiza cliente sin actualizar la tabla...
      for(let i = 0; i < this.clientes.length; i++){
        if( this.clientes[i].idcliente == ev.cliente.idcliente ){
          this.clientes[i] = ev.cliente;
        }
      }
    }
  }
}
