import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MysqlService } from 'src/app/services/mysql.service';

@Component({
  selector: 'app-update-template',
  templateUrl: './update-template.component.html',
  styleUrls: ['./update-template.component.css']
})
export class UpdateTemplateComponent implements OnInit {
  template:{
    idtemplate:number,
    template:string
  };

  constructor( private routes: ActivatedRoute, private ws: MysqlService ) { }

  ngOnInit() {
    this.routes.params.subscribe( (params: Params) => {
      //console.log('Id: ', params.id);
      this.cargarTemplate(params.id);
    })
  }

  cargarTemplate(id:number){
    this.ws.getTemplates(id).subscribe( res => {
      this.template = res ? res[0] : {};
      console.log(this.template)
    })
  };

  guardarCambiosTemplate(){
    let vars = {
      template: this.template.template
    };

    this.ws.updateTemplate(this.template.idtemplate, vars).subscribe( res => {
      if(res == 'success'){
        console.log('Success');
      } else{

      }
    })
  }

  getIdioma(i){
    let idioma = "";
    switch(i){
      case 'spa': idioma = 'Español';
      break;

      case 'eng': idioma = 'Inglés';
      break;

      case 'fra': idioma = 'Francés';
      break;

      case 'ita': idioma = 'Italiano';
      break;
    }

    return idioma;
  }

}
