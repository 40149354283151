import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import * as firebase from "firebase";
import { FirebaseService } from '../services/firebase.service';
import { MysqlService } from '../services/mysql.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  redirectUrl:string;

  constructor( private fb: FirebaseService, private mysql: MysqlService ) { this.verifyLogin() }

  verifyLogin(){
    this.fb.isLogged().then(response => {
      this.fb.user = response;
      if( this.fb.user && this.mysql.asesores.indexOf(this.fb.user.email) != -1 ){
        this.login();
      } else{
        this.logout();
      }
    });    
  }

  login(): Observable<boolean>{
    return of(true).pipe(
      delay(1000),
      tap(val => this.isLoggedIn = true)
    )
  }

  logout(): void{
    this.isLoggedIn = false;
  }
}
