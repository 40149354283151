import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MysqlService } from '../../services/mysql.service';
import { FirebaseService } from '../../services/firebase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-todo-list',
  templateUrl: './home-todo-list.component.html',
  styleUrls: ['./home-todo-list.component.css']
})
export class HomeTodoListComponent implements OnInit {
  fechaFiltro:FormGroup;
  logged:any;
  todoList:any = [];
  day:string;
  counter={pendientes:0, finalizados:0};

  constructor( private ws: MysqlService, private fb: FirebaseService, private router: Router ) { }

  initForm(){
    const fecha = new Date();
    let mes = fecha.getMonth() < 9 ? "0" + (fecha.getMonth()+1) : fecha.getMonth()+1;
    const todoDay = fecha.getFullYear() + '-' + mes + '-' + fecha.getDate();
    this.fechaFiltro = new FormGroup({
      todoDay: new FormControl(todoDay, Validators.required)
    });
    this.day = todoDay;
  }

  ngOnInit() {
    this.initForm();
    this.fb.isLogged();
    const fecha = new Date();
    let mes = fecha.getMonth() < 9 ? "0" + (fecha.getMonth()+1) : fecha.getMonth()+1;
    const todoDay = fecha.getFullYear() + '-' + mes + '-' + fecha.getDate();
    //console.log('Now: ' , this.todoDay);
    this.fechaFiltro.patchValue({
      todoDay: todoDay
    })

    this.ws.notify('Obteniendo lista....', 'info', 1500);
    this.ws.getTodoList(this.fb.auth.currentUser.email, todoDay).subscribe( (response:any) => {
      console.log('Response TodoList: ', response);
      if(response != 'fail'){
        this.todoList = response && response.length > 0 ? response : [];
        for(const a of this.todoList){
          if(!a.status || a.status == 'pendiente'){
            this.counter.pendientes++;
          } else{
            this.counter.finalizados++;
          }
        }
      } else if(response == 'fail'){
        this.todoList = [];
      }
      
    });

    this.fechaFiltro.valueChanges.subscribe( response => {
      this.ws.notify('Actualizando registros....', 'info', 1500);
      this.day = response.todoDay;
      this.ws.getTodoList(this.fb.auth.currentUser.email, response.todoDay).subscribe( (response:any) => {
        console.log('Response TodoList: ', response);
        this.todoList = response.length > 0 ? response : [];
        this.counter.finalizados = 0;
        this.counter.pendientes = 0;
        for(const a of this.todoList){
          if(!a.status || a.status == 'pendiente'){
            this.counter.pendientes++;
          } else{
            this.counter.finalizados++;
          }
        }
      });
    })
  }

  submit(){
    console.log('Form: ', this.fechaFiltro.value);
  }

  changeStatus(todo:any){
    const vars = {
      status: todo.status == 'pendiente' ? 'finalizado' : 'pendiente'
    }

    this.ws.updateTask(todo.idbitacora, vars).subscribe( (response:any) => {
      this.ws.notify('¡Registro actualizado!', 'success', 1500);
      this.counter.finalizados = 0;
      this.counter.pendientes = 0;

      for(var i = 0; i < this.todoList.length; i++){
        if(this.todoList[i].idbitacora == todo.idbitacora){
          this.todoList[i].status = todo.status == 'pendiente' ? 'finalizado' : 'pendiente';
        }

          if(!this.todoList[i].status || this.todoList[i].status == 'pendiente'){
            this.counter.pendientes++;
          } else{
            this.counter.finalizados++;
          }
        
      }
    });
  }

  newTaskToday(){
    this.router.navigateByUrl('/todo/new?e=' + this.day);
  }

}
