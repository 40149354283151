import { Component, OnInit } from '@angular/core';
import { MysqlService } from '../../services/mysql.service';
import { FirebaseService } from '../../services/firebase.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormGroup, FormControl } from '../../../../node_modules/@angular/forms';

@Component({
  selector: 'app-edit-todo-list',
  templateUrl: './edit-todo-list.component.html',
  styleUrls: ['./edit-todo-list.component.css']
})
export class EditTodoListComponent implements OnInit {
  form: FormGroup;
  clientes: any=[];
  id:number;

  constructor(
    private ws: MysqlService, private fb: FirebaseService, 
    private router: Router, private routes: ActivatedRoute) { }

  ngOnInit() {
    this.initForm();
    this.routes.params.subscribe((params: Params) => {
      this.id = params.id;
      this.ws.getTodoList(this.fb.auth.currentUser.email, '', params.id).subscribe( (response:any) => {
        this.patchForm(response[0]);
      })      
    });

    this.ws.getClientes(this.fb.auth.currentUser.email).subscribe((response:any) => {
      if(response != 'fail'){
        this.clientes = response && response.length > 0 ? response : [];
      } else{
        this.clientes = [];
      }
    })
  }

  initForm(){
    this.form = new FormGroup({
      idcliente: new FormControl("", Validators.required),
      fecha: new FormControl("", Validators.required),
      observacion: new FormControl("", Validators.required)
    });
  }

  patchForm(data:any){
    this.form.patchValue({
      idcliente: data.idcliente,
      fecha: data.fecha,
      observacion: data.observacion
    });
  }

  submit(){
    if(this.form.valid){
      let params = this.form.value;
      this.ws.notify('Guardando cambios', '', 3000);
      this.ws.updateTask(this.id, params).subscribe( (response:string) => {
        if(response == 'success'){
          console.log('Response:', response);
          this.router.navigate(['/todo']);
        }
      });
    }
  }

}
