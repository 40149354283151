import { Injectable } from "@angular/core";
import * as firebase from "firebase";
import { NotificationBarService } from 'ngx-notification-bar';
import { MysqlService } from './mysql.service';

@Injectable({
  providedIn: "root"
})
export class FirebaseService {
  db = firebase.firestore();
  store = firebase.storage();
  auth = firebase.auth();
  user: any;

  googleProvider = new firebase.auth.GoogleAuthProvider();

  desarrolladores: any = [];

  constructor( private mysql: MysqlService ) {
    this.setDesarrolladores();
    this.isLogged().then( user => this.user );
  }

  setLogin(credenciales: {email:string, password:string}){
    return this.auth.signInWithEmailAndPassword(credenciales.email, credenciales.password);
  }

  async isLogged(){
    return new Promise(
      (resolve, revoke) => {
        this.auth.onAuthStateChanged(user => {
          if(user){
            resolve(user);
          } else{
            revoke(null);
          }
        })
      }
    )
  }

  setLogout(){
    return this.auth.signOut();
  }

  setDesarrolladores() {
    this.desarrolladores = [];
    this.cargarDesarrolladores().then(res => {
      res.forEach(doc => {
        this.desarrolladores.push(doc);
      });
    });
  }

  cargarDesarrolladores() {
    return this.db.collection("desarrolladores").get();
  }

  getDesarrolladores() {
    return this.desarrolladores;
  }

  createDesarrollador(desarrollador: {}) {
    return this.db
      .collection("desarrolladores")
      .doc()
      .set(desarrollador);
  }

  updateDesarrollador(doc, desarrollador: {}) {
    return this.db
      .collection("desarrolladores")
      .doc(doc)
      .update(desarrollador);
  }

  deleteDesarrollador(doc) {
    return this.db
      .collection("desarrolladores")
      .doc(doc)
      .delete();
  }

  createPropiedad(doc: string, propiedades: any) {
    return this.db
      .collection("desarrolladores")
      .doc(doc)
      .update({ propiedades: propiedades });
  }

  uploadPortadaBlog(name, base64) {
    return this.store
      .ref()
      .child("blog/" + name + ".png")
      .putString(base64, "data_url");
  }

  /** Log Gmail */
  loginGoogle(vars?: {email:string, password: string}){
    /*const allowed = this.mysql.asesores.filter( a => a === vars.email);
    if( allowed ){
      return this.auth.signInWithPopup(this.googleProvider);
    } else{
      this.mysql.notify('No tienes permitido acceder a esta área', 'error', 5000);
    }*/
    return this.auth.signInWithPopup(this.googleProvider);
  }

  setLoginGoogle(token:string){
    return this.auth.signInWithCustomToken(token);
  }
}
