import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-propiedades',
  templateUrl: './home-propiedades.component.html',
  styleUrls: ['./home-propiedades.component.css']
})
export class HomePropiedadesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
