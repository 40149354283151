import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-asesor-leads',
  templateUrl: './modal-asesor-leads.component.html',
  styleUrls: ['./modal-asesor-leads.component.css']
})
export class ModalAsesorLeadsComponent implements OnInit, OnChanges{
  @Input() leadsAsesor: Array<any> = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    console.log('Leads:', this.leadsAsesor);
  }

}
