import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MysqlService } from '../../services/mysql.service';

@Component({
  selector: 'app-delete-cliente',
  templateUrl: './delete-cliente.component.html',
  styleUrls: ['./delete-cliente.component.css']
})
export class DeleteClienteComponent implements OnInit {
  id:number;
  cliente:any;

  constructor( private routes: ActivatedRoute, private ws: MysqlService, private router: Router ) {}

  ngOnInit() {
    this.routes.params.subscribe((params: Params) => {
      this.id = params.id;

      //console.log('ID: ', params.id); 
      for(const a of this.ws.clientes){
        if(a.idcliente == this.id){
          this.cliente = a;
        }
      }
    })
  }

  eliminarCliente(){
    this.ws.deleteCliente({id:this.id}).subscribe( response => {
      if(response == "success"){
        this.router.navigate(['/clientes']);
      } else{
        //console.log('No eliminó cliente');
      }
    });
  }

}
