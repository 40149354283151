import { Component, OnInit } from '@angular/core';
import { MysqlService } from 'src/app/services/mysql.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recientes',
  templateUrl: './recientes.component.html',
  styleUrls: ['./recientes.component.css']
})
export class RecientesComponent implements OnInit {

  clientes:any = [];

  constructor( private ws: MysqlService, private fb: FirebaseService, private router: Router ) {
    this.fb.isLogged();
  }

  async ngOnInit() {
    await this.fb.isLogged();
    this.cargarRecientes();

    setTimeout(() => {
      //Refrescar los recientes cada 5minutos.
      this.cargarRecientes();
    }, 50000);
  } 

  async cargarRecientes(){
    await this.ws.getRecents(this.fb.user.email).subscribe((res:any) => {
      if(res != 'fail'){
        this.clientes = res;
        this.ws.clientes = this.clientes;
        this.ws.setDataTables();
      } else{
        this.clientes = [];
        this.ws.clientes = [];
        this.ws.setDataTables();
      }
    })
  }

  editarCliente(cliente){
    localStorage.setItem("cliente", JSON.stringify(cliente));
    this.router.navigate(['/clientes', cliente.idcliente, 'edit']);
  }

}
