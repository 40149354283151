import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal-notas-consulta',
  templateUrl: './modal-notas-consulta.component.html',
  styleUrls: ['./modal-notas-consulta.component.css']
})
export class ModalNotasConsultaComponent implements OnInit, OnChanges {
  @Input() cliente;
  @Output() onClose = new EventEmitter<boolean>();
  
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    console.log('Selected: ', this.cliente);
  }

}
