import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { RouterModule, Routes } from "@angular/router";

import { DataTablesModule } from "angular-datatables";
//import { NgxEditorModule } from "ngx-editor";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxTextEditorModule } from 'ngx-text-editor';
/*
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
*/
import { NotificationBarModule } from 'ngx-notification-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { initializeApp } from "firebase";
import { FirebaseService } from "./services/firebase.service";
import { DesarrolladoresComponent } from "./desarrolladores/desarrolladores.component";
import { CreateDesarrolladorComponent } from "./desarrolladores/create-desarrollador/create-desarrollador.component";

import { HomeDesarrolladoresComponent } from "./desarrolladores/home-desarrolladores/home-desarrolladores.component";
import { PropiedadesComponent } from "./desarrolladores/propiedades/propiedades.component";
import { HomePropiedadesComponent } from "./desarrolladores/propiedades/home-propiedades/home-propiedades.component";
import { CreatePropiedadComponent } from "./desarrolladores/propiedades/create-propiedad/create-propiedad.component";
import { UpdateDesarrolladorComponent } from "./desarrolladores/update-desarrollador/update-desarrollador.component";
import { DeleteDesarrolladorComponent } from "./desarrolladores/delete-desarrollador/delete-desarrollador.component";
import { UpdatePropiedadComponent } from "./desarrolladores/propiedades/update-propiedad/update-propiedad.component";
import { DeletePropiedadComponent } from "./desarrolladores/propiedades/delete-propiedad/delete-propiedad.component";
import { BlogComponent } from "./blog/blog.component";
import { MysqlService } from "./services/mysql.service";
import { CreatePostComponent } from "./blog/create-post/create-post.component";
import { HomeBlogComponent } from "./blog/home-blog/home-blog.component";
import { ProyectosComponent } from "./desarrolladores/proyectos/proyectos.component";
import { CreateProyectoComponent } from "./desarrolladores/proyectos/create-proyecto/create-proyecto.component";
import { UpdateProyectoComponent } from "./desarrolladores/proyectos/update-proyecto/update-proyecto.component";
import { DeleteProyectoComponent } from "./desarrolladores/proyectos/delete-proyecto/delete-proyecto.component";
import { HomeProyectosComponent } from "./desarrolladores/proyectos/home-proyectos/home-proyectos.component";
import { PricesPropiedadesComponent } from "./desarrolladores/propiedades/prices-propiedades/prices-propiedades.component";
import { CreatePriceComponent } from "./desarrolladores/propiedades/prices-propiedades/create-price/create-price.component";
import { ClientesComponent } from './clientes/clientes.component';
import { HomeClientesComponent } from './clientes/home-clientes/home-clientes.component';
import { ClienteComponent } from './clientes/cliente/cliente.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { MenuComponent } from './core/menu/menu.component';
import { CreateClienteComponent } from './clientes/create-cliente/create-cliente.component';
import { UpdateClienteComponent } from './clientes/update-cliente/update-cliente.component';
import { DeleteClienteComponent } from './clientes/delete-cliente/delete-cliente.component';
import { BitacoraClienteComponent } from './clientes/bitacora-cliente/bitacora-cliente.component';

import { TodoComponent } from './todo/todo.component';
import { HomeTodoListComponent } from './todo/home-todo-list/home-todo-list.component';
import { EditTodoListComponent } from './todo/edit-todo-list/edit-todo-list.component';
import { NewTodoListComponent } from './todo/new-todo-list/new-todo-list.component';

import { RecientesComponent } from './clientes/recientes/recientes.component';
import { DatabaseClientesComponent } from './clientes/database-clientes/database-clientes.component';
import { ReportesComponent } from './reportes/reportes.component';
import { NoCacheHeadersInterceptor } from './services/nocacheInterceptor';
import { TemplatesComponent } from './templates/templates.component';
import { UpdateTemplateComponent } from './templates/update-template/update-template.component';
import { CreateNotaClienteComponent } from './clientes/create-nota-cliente/create-nota-cliente.component';
import { AdminClientesComponent } from './clientes/admin-clientes/admin-clientes.component';
import { BlacklistClientesComponent } from './clientes/blacklist-clientes/blacklist-clientes.component';
import { ModalNotasConsultaComponent } from './modals/modal-notas-consulta/modal-notas-consulta.component';
import { ModalClienteEditarComponent } from './modals/modal-cliente-editar/modal-cliente-editar.component';
import { TableToExcelComponent } from './reportes/table-to-excel/table-to-excel.component';
import { ModalBitacoraConsultaComponent } from './modals/modal-bitacora-consulta/modal-bitacora-consulta.component';
import { ModalClienteStatusComponent } from './modals/modal-cliente-status/modal-cliente-status.component';
import { ModalAsesorLeadsComponent } from './modals/modal-asesor-leads/modal-asesor-leads.component';

const config = {
  apiKey: "AIzaSyDJ5iQD8515r6w3eVcSzo6C91GpgF1ylKk",
  authDomain: "evoke-13e3e.firebaseapp.com",
  databaseURL: "https://evoke-13e3e.firebaseio.com",
  projectId: "evoke-13e3e",
  storageBucket: "evoke-13e3e.appspot.com",
  messagingSenderId: "373112913294",
  appId: "1:373112913294:web:bba66a6593f96279"
};

initializeApp(config);

const appRoutes: Routes = [
  { path: "login", component: AuthComponent },
  {
    path: "clientes",
    component: ClientesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: HomeClientesComponent, pathMatch: 'full' },
      { path: "admin", component: AdminClientesComponent },
      { path: "recents", component: RecientesComponent },
      { path: "new", component: CreateClienteComponent },
      { path: "bitacora/:id", component: BitacoraClienteComponent },
      { path: "database", component: DatabaseClientesComponent },
      { path: "blacklist", component: BlacklistClientesComponent },
      { path: ":id", component: ClienteComponent },
      { path: ":id/edit", component: UpdateClienteComponent },
      { path: ":id/delete", component: DeleteClienteComponent },
      { path: ":id/notas/new", component: CreateNotaClienteComponent },
    ]
  },
  {
    path: "desarrolladores",
    component: DesarrolladoresComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: HomeDesarrolladoresComponent },
      { path: "new", component: CreateDesarrolladorComponent },
      ///....
      { path: ":id/edit", component: UpdateDesarrolladorComponent },
      { path: ":id/delete", component: DeleteDesarrolladorComponent },
      ///...
      { path: ":id/proyectos", component: ProyectosComponent },
      { path: ":id/proyectos/new", component: CreateProyectoComponent },
      ///...
      {
        path: ":id/proyectos/:idp/propiedades",
        component: PropiedadesComponent
      },

      {
        path: ":id/proyectos/:idp/propiedades/new",
        component: CreatePropiedadComponent
      },
      {
        path: ":id/proyectos/:idp/propiedades/:index/prices",
        component: PricesPropiedadesComponent
      },
      {
        path: ":id/proyectos/:idp/propiedades/:index/prices/new",
        component: CreatePriceComponent
      },
      {
        path: ":id/proyectos/:idp/propiedades/:index/edit",
        component: UpdatePropiedadComponent
      },
      {
        path: ":id/proyectos/:idp/propiedades/:index/delete",
        component: DeletePropiedadComponent
      }
    ]
  },
  {
    path: "blog",
    component: BlogComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: HomeBlogComponent },
      { path: "new", component: CreatePostComponent }
    ]
  },
  {
    path: "todo",
    component: TodoComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", component: HomeTodoListComponent, pathMatch: 'full' },
      { path: "new", component: NewTodoListComponent },
      { path: ":id/edit", component: EditTodoListComponent }
    ]
  },
  {
    path: "reportes",
    component: ReportesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "templates",
    component: TemplatesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: ":id/edit", component: UpdateTemplateComponent, outlet: 'editTemplate' }
    ]
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full"
  } /*,
  { path: "**", component: PageNotFoundComponent }*/
];

@NgModule({
  declarations: [
    AppComponent,
    DesarrolladoresComponent,
    CreateDesarrolladorComponent,
    UpdateDesarrolladorComponent,
    HomeDesarrolladoresComponent,
    PropiedadesComponent,
    HomePropiedadesComponent,
    CreatePropiedadComponent,
    DeleteDesarrolladorComponent,
    UpdatePropiedadComponent,
    DeletePropiedadComponent,
    BlogComponent,
    HomeBlogComponent,
    CreatePostComponent,
    ProyectosComponent, CreateProyectoComponent, UpdateProyectoComponent, DeleteProyectoComponent,
    HomeProyectosComponent,
    PricesPropiedadesComponent,
    CreatePriceComponent,
    ClientesComponent, ClienteComponent, HomeClientesComponent,
    AuthComponent, MenuComponent, 
    CreateClienteComponent, UpdateClienteComponent, DeleteClienteComponent, BitacoraClienteComponent, 
    TodoComponent, HomeTodoListComponent, EditTodoListComponent, NewTodoListComponent, RecientesComponent, DatabaseClientesComponent, ReportesComponent, 
    TemplatesComponent, UpdateTemplateComponent, CreateNotaClienteComponent, AdminClientesComponent, BlacklistClientesComponent, ModalNotasConsultaComponent, ModalClienteEditarComponent, TableToExcelComponent, ModalBitacoraConsultaComponent, ModalClienteStatusComponent, ModalAsesorLeadsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule,
    //NgxEditorModule,
    BrowserAnimationsModule,
    NotificationBarModule,
    AngularEditorModule,
    ImageCropperModule,
    NgxTextEditorModule,
    RouterModule.forRoot(
      appRoutes/*,
      { enableTracing: true } // <-- debugging purposes only*/
    )
  ],
  providers: [FirebaseService, MysqlService, { 
    provide: HTTP_INTERCEPTORS, 
    useClass: NoCacheHeadersInterceptor, 
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {}
