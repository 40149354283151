import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MysqlService } from 'src/app/services/mysql.service';

declare var $:any;

@Component({
  selector: 'app-modal-cliente-status',
  templateUrl: './modal-cliente-status.component.html',
  styleUrls: ['./modal-cliente-status.component.css']
})
export class ModalClienteStatusComponent implements OnInit {
  id:number;
  @Input() cliente:any;
  @Output() onUpdate = new EventEmitter<any>();
  form: FormGroup;

  vinculos:any;
  status:Array<any> = [];
  estados:Array<any> = [];
  idiomas:any;

  user:any;
  asesores:any = [];
  notas:Array<any> = [];

  constructor( private ws: MysqlService, private fb: FirebaseService ) {
    this.fb.isLogged();
    this.status = this.ws.status;
    this.user = this.fb.user;
  }

  ngOnInit() { }

  ngOnChanges(){
    this.initForm();
    if( this.cliente ){
      // Cambiar status
      this.estados = [];
      for(const a of this.status) {
        if(a === this.cliente.status) {
          this.estados.push(a);
        } else if(this.estados.length > 0){
          this.estados.push(a);
        }
      }
      this.patchForm(this.cliente);
    }
  }

  initForm(){
    this.form = new FormGroup({
      old_status: new FormControl("", Validators.required),
      status: new FormControl("", Validators.required),
      comentarios: new FormControl("", Validators.required)
    });
  }

  patchForm(cliente){
    this.form.patchValue({
      old_status: cliente.status,
      status: cliente.status
    });
  }

  submit(){
    const vars = this.form.value;

    if( vars.old_status === vars.status ) {
      this.ws.notify('Es necesario cambiar el estado para dar seguimiento', 'info', 1500);
      return 1;
    }
    
    this.ws.seguimientoCliente(this.cliente.idcliente, vars).subscribe(res => {
      
      if(res != 'fail'){
        this.cliente = {...this.cliente, status: vars.status};
        
        this.ws.notify('¡Cliente actualizado correctamente!', 'success', 1500);
        this.onUpdate.emit({isUpdated: true, cliente: this.cliente});
        $('.modal').modal('hide');
      } else{
        this.ws.notify('Hubo un problema al editar el cliente', 'error', 3000);
      }
      
    }, () => {
      this.ws.notify('Problema de conexión... Inténtalo de nuevo', 'warning', 3000);
    });
  }

}
