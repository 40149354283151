import { Component, OnInit } from "@angular/core";
import { FirebaseService } from "src/app/services/firebase.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-delete-desarrollador",
  templateUrl: "./delete-desarrollador.component.html",
  styleUrls: ["./delete-desarrollador.component.css"]
})
export class DeleteDesarrolladorComponent implements OnInit {
  form: FormGroup;
  id: string;
  desarrollador: any = {};

  constructor(
    private ws: FirebaseService,
    private router: Router,
    private routes: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initForm();
    this.routes.params.subscribe((params: Params) => {
      this.id = params.id;
      this.patchForm();
    });
  }

  initForm() {
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      contacto: new FormControl("", Validators.required),
      telefono: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      status: new FormControl("", Validators.required),
      observaciones: new FormControl("", Validators.required)
    });
  }

  patchForm() {
    const des = this.ws.getDesarrolladores();
    let mainDes: any = {};

    for (const a of des) {
      if (a.id == this.id) {
        mainDes = a;
      }
    }

    this.desarrollador = mainDes;

    this.form.patchValue({
      nombre: mainDes.data().nombre,
      contacto: mainDes.data().contacto,
      telefono: mainDes.data().telefono,
      email: mainDes.data().email,
      status: mainDes.data().status,
      observaciones: mainDes.data().observaciones
    });
  }

  deleteDesarrollador() {
    console.log(this.form.value);
    if (this.form.valid) {
      let ob = this.form.value;
      this.ws
        .deleteDesarrollador(this.id)
        .then(res => {
          console.log("Desarrollador eliminado...");
          this.router.navigate(["/desarrolladores"]);
        })
        .catch(err => {
          console.log("Error ", err);
        });
    }
  }
}
