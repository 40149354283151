import { Component, OnInit } from "@angular/core";
import { FirebaseService } from "src/app/services/firebase.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MysqlService } from '../../../services/mysql.service';

@Component({
  selector: "app-update-propiedad",
  templateUrl: "./update-propiedad.component.html",
  styleUrls: ["./update-propiedad.component.css"]
})
export class UpdatePropiedadComponent implements OnInit {
  form: FormGroup;
  id: number;
  id2: number;
  index: number;
  desarrollador: any = {};
  propiedad: any = {};
  tipos_unidades:any = [];

  constructor(
    private ws: MysqlService,
    private router: Router,
    private routes: ActivatedRoute
  ) {}

  ngOnInit() {
    
    this.routes.params.subscribe((params: Params) => {
      this.initForm();
      this.id = params.id;
      this.id2 = params.idp;
      this.index = params.index;
      this.patchForm(params.id);
    });

    this.tipos_unidades = this.ws.unidades;
  }

  initForm() {
    this.form = new FormGroup({
      recamaras: new FormControl("", Validators.required),
      tipo_unidad: new FormControl("", Validators.required),
      fecha_entrega: new FormControl("", [Validators.required]),
      drive: new FormControl("", Validators.required),
      observaciones: new FormControl(""),
      status: new FormControl("", Validators.required),
      m2: new FormControl("", [Validators.required, Validators.min(0)]),
      portada: new FormControl("")
    });
  }

  patchForm(id:number) {
    this.ws.getDesarrolladores(id).subscribe( (res:any) => {
      if(res != 'fail'){

        for (const a of res) {
          console.log('Desa: ', a);

          if (a.iddesarrollador == this.id) {
            for(const b of a.proyectos){
              console.log('Proy: ', b);

              if(b.idproyecto == this.id2){
                for(const c of b.propiedades){
                  console.log('Prop: ', c);

                  if(c.idpropiedad == this.index){
                    this.propiedad = c;
                  }
                }
              }
            } 
          }
        }

        console.log('Propiedad: ', this.propiedad);

        this.form.patchValue({
          recamaras: this.propiedad.recamaras,
          status: this.propiedad.status,
          tipo_unidad: this.propiedad.tipo_unidad,
          fecha_entrega: this.propiedad.fecha_entrega,
          drive: this.propiedad.drive,
          observaciones: this.propiedad.observaciones,
          m2: this.propiedad.m2,
          portada: this.propiedad.portada
        });
      }
    });
  }

  submit() {
    if (this.propiedad) {
      this.ws
        .updatePropiedad({ id:this.index, vars: this.form.value })
        .subscribe(res => {
          if(res == 'success'){
            this.router.navigate([
              "/desarrolladores",
              this.id,
              "proyectos",
              this.id2,
              "propiedades"
            ]);
          }
        })
    }
  }
}
