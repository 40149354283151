import { Component, OnInit } from '@angular/core';
import { MysqlService } from '../services/mysql.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {
  templates = [];

  constructor( private ws: MysqlService ) { }

  ngOnInit() {
    this.cargarTemplates();
  }

  cargarTemplates(){
    this.ws.getTemplates().subscribe( (res:any) => {
      console.log('Response: ', res);
      this.templates = res;
    });

  }

  getIdioma(i){
    let idioma = "";
    switch(i){
      case 'spa': idioma = 'Español';
      break;

      case 'eng': idioma = 'Inglés';
      break;

      case 'fra': idioma = 'Francés';
      break;

      case 'ita': idioma = 'Italiano';
      break;
    }

    return idioma;
  }

}
