import { Component, OnInit } from "@angular/core";
import { MysqlService } from "src/app/services/mysql.service";

@Component({
  selector: "app-home-blog",
  templateUrl: "./home-blog.component.html",
  styleUrls: ["./home-blog.component.css"]
})
export class HomeBlogComponent implements OnInit {
  posts: any = [];

  constructor(private ws: MysqlService) {}

  ngOnInit() {
    this.ws.getBlog(5).subscribe(res => {
      if (res) {
        this.posts = res;
      }
    });
  }
}
