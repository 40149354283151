import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MysqlService } from "src/app/services/mysql.service";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-create-proyecto",
  templateUrl: "./create-proyecto.component.html",
  styleUrls: ["./create-proyecto.component.css"]
})
export class CreateProyectoComponent implements OnInit {
  form: FormGroup;
  lugares: any = [];
  id: number;

  constructor(
    private ws: MysqlService,
    private router: Router,
    private routes: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initForm();
    this.obtenerLugares();
    this.routes.params.subscribe((params: Params) => (this.id = params.id));
  }

  initForm() {
    this.form = new FormGroup({
      proyecto: new FormControl("", Validators.required),
      idlugar: new FormControl("", Validators.required),
      status: new FormControl("", Validators.required)
    });
  }

  submit() {
    if (this.form.valid) {
      console.log("Valies: ", this.form.value);
      let vars = this.form.value;
      vars.iddesarrollador = this.id;
      this.ws.createProyecto(vars).subscribe(res => {
        console.log("Creado: ", res);
        if (res != "fail") {
          this.router.navigate(["/desarrolladores", this.id, "proyectos"]);
        }
      });
    }
  }

  obtenerLugares() {
    this.ws.getLugares().subscribe(res => {
      if (res) {
        this.lugares = res;
      }
    });
  }
}
