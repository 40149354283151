import { Component, OnInit } from "@angular/core";
import { Params, ActivatedRoute } from "@angular/router";
import { MysqlService } from "src/app/services/mysql.service";

@Component({
  selector: "app-prices-propiedades",
  templateUrl: "./prices-propiedades.component.html",
  styleUrls: ["./prices-propiedades.component.css"]
})
export class PricesPropiedadesComponent implements OnInit {
  id: number;
  id2: number;
  index: number;
  propiedades = [];
  precios = [];

  constructor(private routes: ActivatedRoute, private ws: MysqlService) {}

  ngOnInit() {
    this.routes.params.subscribe((params: Params) => {
      //this.setDataTables();
      console.log("Params:", params);
      this.id = params.id;
      this.id2 = params.idp;
      this.index = params.index;
      this.obtenerProyectos(params.id);
    });
  }

  obtenerProyectos(id) {
    this.ws.getDesarrolladores(id).subscribe(res => {
      if (res != "fail") {
        const desarrollador = res[0];
        const proyectos = res != "fail" ? desarrollador.proyectos : [];
        if (proyectos) {
          this.propiedades = this.obtenerPropiedades(proyectos, this.id2);
        }
      }
    });
  }

  obtenerPropiedades(proyectos, id) {
    for (const a of proyectos) {
      if (a.idproyecto == id) {
        this.precios = this.obtenerPrecios(a.propiedades, this.index);

        console.log("Preicos", this.precios);
      }
    }

    return [];
  }

  obtenerPrecios(propiedades, id) {
    for (const a of propiedades) {
      if (a.idpropiedad == id) {
        return a.precios;
      }
    }
    return [];
  }
}
