import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MysqlService } from '../services/mysql.service';

declare var $:any;

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {
  mensuales:any = [];
  vendedores:any = [];
  totales = {name:'totales', leads:0, nuevos:0, contactados:0, database:0};
  totalesMes = {name:'totales', leads:0, nuevos:0, contactados:0, database:0};
  admins = ['sales@evokeinternational.com.mx', 'mkt@evokeinternational.com.mx', 'direccion@evokeinternational.com.mx', 'edson@evokeinternational.com.mx'];
  fecha = new Date();
  filtro = {
    fecha: "",
    fechaF: ""
  };

  lead:any = "";

  leads: any;
  leadsAsesor: any;

  constructor( private mysql: MysqlService, private routes: ActivatedRoute ) { }

  ngOnInit() {
    for(const a of this.mysql.asesores){
      if( this.admins.indexOf(a) != -1 ){ continue; }
      this.vendedores.push({email:a, leads:[], nuevos:0, contactados:0, database:0});
      this.mensuales.push({email:a, leads:[], nuevos:0, contactados:0, database:0});
    }
    //this.obtenerLeads();
    //this.obtenerLeadsMensuales();
    //this.obtenerLeadsProyecto();
    this.obtenerFechasFiltro();
    this.getLeadsFiltro();

    //this.mysql.setDataTables();
  }

  generarReporte( reporte: string ){
    //console.log('Topo de reporte: ', reporte);
  }

  obtenerFechasFiltro() {
    this.routes.queryParamMap.subscribe( (queryParams: Params) => {
      if( queryParams && queryParams.params ) {
        this.filtro = {
          fecha: queryParams.params.fi,
          fechaF: queryParams.params.ff
        }

        console.log('Filtro: ', this.filtro);
      }
    });
  }

  obtenerLeads(){
    for(let i = 0; i < this.vendedores.length; i++){
      if( this.admins.indexOf(this.vendedores[i].email) > 0 ){ continue; }
      this.mysql.getClientes(this.vendedores[i].email).subscribe( (leads:any) => {
        this.vendedores[i].leads = leads && leads.length > 0 ? leads : [];
        this.totales.leads += leads && leads.length > 0 ? leads.length : 0;

        for(const a of leads){
          switch(a.status){
            case 'nuevo': this.vendedores[i].nuevos++;
            this.totales.nuevos++;
            break;

            case 'database': this.vendedores[i].database++;
            this.totales.database++;
            break;

            default: this.vendedores[i].contactados++;
            this.totales.contactados++;
            break;
          }
        }
      });
    }

    console.log(' Vendedores: ', this.vendedores);
  }

  obtenerLeadsMensuales(){
    const mes = this.fecha.getMonth() + 1;
    for(let i = 0; i < this.mensuales.length; i++){
      if( this.admins.indexOf(this.mensuales[i].email) > 0 ){ continue; }
      this.mysql.getClientes(this.mensuales[i].email).subscribe( (leads:any) => {
        //this.mensuales[i].leads = leads && leads.length > 0 ? leads : [];
        //this.totales.leads += leads && leads.length > 0 ? leads.length : 0;

        for(const a of leads){
          //Verificar si son del mes.
          const fecha = new Date(a.alta);
          const mesComp = fecha.getMonth() + 1;
          if( mes != mesComp ){
            continue;
          }

          this.mensuales[i].leads.push(a);
          this.totalesMes.leads++;

          switch(a.status){
            case 'nuevo': this.mensuales[i].nuevos++;
            this.totalesMes.nuevos++;
            break;

            case 'database': this.mensuales[i].database++;
            this.totalesMes.database++;
            break;

            default: this.mensuales[i].contactados++;
            this.totalesMes.contactados++;
            break;
          }
        }
      });
    }

    console.log(' Vendedores Mensuales: ', this.mensuales);
  }

  obtenerLeadsProyecto(){
    const vars = this.filtro && this.filtro.fecha != "" ? this.filtro : null;
    this.mysql.getLeadsProyecto(vars).subscribe( (res:any) => {
      this.leads = res && res.length > 0 ? res : []
    });
  }

  getLeadsFiltro(){
    //this.obtenerLeadsProyecto();
    const filtros = {
      finicio: this.filtro.fecha,
      ffin: this.filtro.fechaF,
    };

    this.mysql.getReporteLeadsAjax(filtros).subscribe( (callback: {leads: Array<any>, historico: Array<any>}) => {
      if( callback ) {
        console.log('Entra', callback);
        this.leads = callback;

        this.filtrarLeads(callback.leads);
      }
    });
  }

  verCliente(cliente:any){
    this.lead = cliente;
  }

  filtrarLeads( leads: Array<any>) {
    //Filtrar por asesor
    this.leads.leadsAsesor = [];
    for(let i = 0; i < this.mysql.asesores.length; i++) {
      this.leads.leadsAsesor.push( 
        {
          asesor: this.mysql.asesores[i].split('@')[0], 
          leads_recibidos: leads.filter(l => l.asesor === this.mysql.asesores[i]),
          nuevos: leads.filter(l => l.asesor === this.mysql.asesores[i] && l.status === 'nuevo'),
          actualizados: leads.filter(l => l.asesor === this.mysql.asesores[i] && l.status != 'nuevo')
        }
      );
    }

    //console.log('Asesor', this.leads.leadsAsesor);
    this.mysql.setDataTables();
  }

  calcularDiasPromedio(leads: Array<any>){
    let dias = 0;
    const today = new Date();
    let nuevos = 0;

    for(let j = 0; j < leads.length; j++) {
      if(leads[j].status !== 'nuevo' ) {
        continue;
      }

      nuevos = nuevos + 1;

      //console.log('Fecha', leads[j].fecha);
      let df = leads[j].fecha.split(' ');
      let hora = df[1].split(':');
      let f = df[0].split('-');
      //console.log('F', f[0], 'm', f[1], 'd', f[2]);
      //console.log('F', hora[0], 'm', hora[1], 's', hora[2]);

      //let fecha1: Date = new Date(leads[j].fecha);
      let fecha1: Date = new Date( Date.UTC(f[0], f[1] - 1, f[2], hora[0], hora[1], hora[2]) );
      let fecha2: Date;

      if( leads[j].fecha_status && leads[j].fecha_status != '' ) {
        let df = leads[j].fecha_status.split(' ');
        let hora = df[1].split(':');
        let f = df[0].split('-');
        fecha2 = new Date( Date.UTC(f[0], f[1] - 1, f[2], hora[0], hora[1], hora[2]) );
        //console.log('Fechas: ', fecha1, ' - ', fecha2);
      } else if(leads[j].status != 'nuevo'){
        let df = leads[j].fecha_status.split(' ');
        let hora = df[1].split(':');
        let f = df[0].split('-');
        const alta = Date.UTC(f[0], f[1] - 1, f[2], hora[0], hora[1], hora[2]);
        fecha2 = leads[j].status != 'nuevo' ? new Date(alta) : today;
      } else{
        fecha2 = today;
        //console.log('Nuevo: ', fecha1, ' - ', fecha2);        
      }

      const diff = fecha2.getTime() - fecha1.getTime(); 
      
      // To calculate the no. of days between two dates 
      //const diff_dias = diff;

      dias = dias + diff;
    }

    dias = dias / (1000 * 3600 * 24);
    //console.log('Dias', dias, 'leads nuevos', nuevos);
    let promedio: any = dias/nuevos;
    return isNaN(promedio) ? 0 : promedio.toFixed(2);
  }

  obtenerReporteFiltro(){
    $("#tablaFiltrada").table2excel({
        exclude: ".excludeThisClass",
        name: "leads",
        filename: "Leads_Filtrados.xls", // do include extension
        preserveColors: false // set to true if you want background colors and font colors preserved
    });
  }

  verLeadsAsesor(leads) {
    this.leadsAsesor = leads;
    //console.log('Leads', leads);
  }
}