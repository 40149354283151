import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MysqlService } from "src/app/services/mysql.service";

@Component({
  selector: "app-create-desarrollador",
  templateUrl: "./create-desarrollador.component.html",
  styleUrls: ["./create-desarrollador.component.css"]
})
export class CreateDesarrolladorComponent implements OnInit {
  form: FormGroup;
  lugares: any = [];

  constructor(private ws: MysqlService, private router: Router) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      contacto: new FormControl(""),
      telefono: new FormControl(""),
      email: new FormControl(""),
      status: new FormControl(""),
      observaciones: new FormControl("")
    });
  }

  obtenerLugares() {
    this.ws
      .getLugares()
      .subscribe(res => (this.lugares = res != "fail" ? res : []));
  }

  submit() {
    console.log(this.form.value);
    if (this.form.valid) {
      let ob = this.form.value;
      //ob.propiedades = [];

      this.ws.createDesarrollador(ob).subscribe(res => {
        console.log("Nuevo desarrollador creado...");
        this.router.navigate(["/desarrolladores"]);
      });
    }
  }
}
