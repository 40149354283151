import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FirebaseService } from '../services/firebase.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { MysqlService } from '../services/mysql.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  err:string;
  form: FormGroup;
  fecha: string = "";

  constructor( private fb: FirebaseService, private authService: AuthService, private router: Router, private mysql: MysqlService ) {}

  ngOnInit() {
    this.initForm();
    this.verificaLogin();
    
    const fecha = new Date();
    let p:any = fecha.toLocaleDateString();
    p = p.split("/");
    this.fecha = fecha.toLocaleDateString();
  }

  initForm(){
    this.form = new FormGroup({
      email: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required)
    })
  }

  async verificaLogin(){
    await this.fb.isLogged().then(
      (response:any) => this.fb.user = response
    );

    if(this.fb.user.email){
      this.authService.login().subscribe(() => {
        if(this.authService.isLoggedIn){
          let redirect = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : '/clientes';

          this.router.navigateByUrl(redirect);
        }
      })
    }
  }

  submit(){
    if(this.form.valid){
      this.fb.setLogin(this.form.value).then( () => {
        this.authService.login().subscribe(() => {
          if(this.authService.isLoggedIn){
            let redirect = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : '/clientes';

            this.router.navigateByUrl(redirect);
          }
        })
      }).catch( err => {
        this.err = err.message;
        console.log('Bad: ', err)
      })
    }
  }

  async logGmail(){
    await this.fb.loginGoogle().then((result:any) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      console.log('Existe?', this.mysql.asesores.filter(a => a === user.email));

      if( !this.mysql.asesores.filter(a => a === user.email).length ){
        this.mysql.notify('No tienes acceso a este sitio', 'danger');
        this.fb.setLogout().then(() => {
          console.log('Now allowed');
        }).catch();

        return;
      }

      this.fb.isLogged().then( response =>{
        this.fb.user = response;
        this.authService.login().subscribe(() => {
          if(this.authService.isLoggedIn){
            //window.location.href = 'http://test.evokeinternational.com.mx';
            location.reload();

            //let redirect = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : '/clientes';
            //this.router.navigateByUrl(redirect);
          }
        })
      });

      
    }).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
  }
}
