import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-desarrolladores",
  templateUrl: "./desarrolladores.component.html",
  styleUrls: ["./desarrolladores.component.css"]
})
export class DesarrolladoresComponent implements OnInit {
  title: string = "Desarrolladores / Propiedades";
  desarrolladores: any = [];

  constructor() {}

  ngOnInit() {}
}
