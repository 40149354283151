import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MysqlService } from "src/app/services/mysql.service";

@Component({
  selector: "app-home-desarrolladores",
  templateUrl: "./home-desarrolladores.component.html",
  styleUrls: ["./home-desarrolladores.component.css"]
})
export class HomeDesarrolladoresComponent implements OnInit {
  title: string = "Desarrolladores";
  desarrolladores: any = [];
  precios: Array<{lugar:string, precios:Array<number>}>;
  divisa:any= {};

  constructor(private ws: MysqlService, private router: Router) {}

  setDataTables() {
    setTimeout(() => {
      $(".dataTables").DataTable({
        language: {
          lengthMenu: "Mostrar _MENU_ registros por página",
          zeroRecords: "No hay resultados",
          info: "Mostrando página _PAGE_ de _PAGES_",
          infoEmpty: "No hay resultados",
          infoFiltered: "(filtrados de _MAX_ registros totales)",
          processing: "Procesando...",
          search: "Buscar:",
          paginate: {
            first: "Primera",
            last: "Última",
            next: "Siguiente",
            previous: "Anterior"
          },
          aria: {
            sortAscending: ": activar orden ascendente",
            sortDescending: ": activar orden descendente"
          }
        }
      });
    }, 1500);
  }

  ngOnInit() {
    //this.ws.cargarDesarrolladores();
    this.ws.getExchange().subscribe( (response:{rates:any,date:any,base:string}) => {
      console.log('Exchange API: ', response);
      this.divisa = response ? response : {};
    })

    let arr = [];
    this.ws.getDesarrolladores().subscribe(res => {
      this.desarrolladores = res != "fail" ? res : [];
      /*for(const a of this.desarrolladores){
        for(const b of a.proyectos){
          let arr = {lugar:b.lugar, precios: []};
          for(const c of b.propiedades){
            for(const d of c.precios){
              arr.precios.push(d);
            }
          }
          this.precios.push(arr);
        }
      }*/

      console.log('Precios: ', this.precios);
      this.setDataTables();
    });
  }

  verProyectos(id: string) {
    this.router.navigate(["/desarrolladores", id, "proyectos"]);
  }
}
