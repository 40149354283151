import { Component, OnInit } from "@angular/core";
import { AuthService } from './auth/auth.service';
import { FirebaseService } from './services/firebase.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  title = "Evoke Real Estate Group";

  constructor() {
  }

  ngOnInit() {
  }
}
