import { Component, OnInit } from '@angular/core';
import { MysqlService } from '../../services/mysql.service';
import { FirebaseService } from '../../services/firebase.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-todo-list',
  templateUrl: './new-todo-list.component.html',
  styleUrls: ['./new-todo-list.component.css']
})
export class NewTodoListComponent implements OnInit {
  form:FormGroup;
  clientes:any=[];

  constructor( private ws: MysqlService, private fb: FirebaseService, private router: Router, private routes: ActivatedRoute ) { }

  ngOnInit() {
    this.initForm();
    this.routes.queryParams.subscribe(response => {
      console.log('Params: ', response);
      this.form.patchValue({
        fecha: response.e
      })
    });

    this.ws.getClientes(this.fb.auth.currentUser.email).subscribe((response:any) => {
      if(response != 'fail'){
        this.clientes = response && response.length > 0 ? response : [];
      } else{
        this.clientes = [];
      }
    })
  }

  initForm(){
    this.form = new FormGroup({
      idcliente: new FormControl("", Validators.required),
      fecha: new FormControl("", Validators.required),
      observacion: new FormControl("", Validators.required)
    });
  }

  submit(){
    if(this.form.valid){
      let params = this.form.value;
      this.ws.createBitacora(params).subscribe( (response:string) => {
        if(response == 'success'){
          console.log('Response:', response);
          this.router.navigate(['/todo']);
        }
      });
    }
  }

}
