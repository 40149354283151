import { Component, OnInit } from '@angular/core';
import { MysqlService } from '../services/mysql.service';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {
  clientes:any = [];

  constructor( private fb: FirebaseService, private ws: MysqlService ) { }

  ngOnInit() {
    this.fb.isLogged().then((response:any) => {
      this.ws.getClientes(response.email).subscribe(res => {
        //console.log('Clientes:', res);
        if(res){
          this.clientes = res;
        } else{
          this.clientes = [];
        }
      })
    })
    
  }

}
