import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MysqlService } from 'src/app/services/mysql.service';
import { FirebaseService } from 'src/app/services/firebase.service';

declare var $:any;

@Component({
  selector: 'app-modal-cliente-editar',
  templateUrl: './modal-cliente-editar.component.html',
  styleUrls: ['./modal-cliente-editar.component.css']
})
export class ModalClienteEditarComponent implements OnInit, OnChanges {
  id:number;
  @Input() cliente:any;
  @Output() onUpdate = new EventEmitter<any>();
  form: FormGroup;
  form2: FormGroup;

  vinculos:any;
  status:any;
  idiomas:any;

  user:any;
  asesores:any = [];
  notas:Array<any> = [];

  constructor( private ws: MysqlService, private fb: FirebaseService ) {
    this.fb.isLogged();
    this.vinculos = this.ws.vinculos;
    this.status = this.ws.status;
    this.idiomas = this.ws.idiomas;
    this.user = this.fb.user;
    this.asesores = this.ws.asesores;
  }

  ngOnInit() {
    
  }

  ngOnChanges(){
    this.initForm();
    if( this.cliente ){
      this.cliente.notas = !this.cliente.notas ? [] : this.cliente.notas;
      const nota = {
        nota: this.cliente.observaciones,
        fecha: this.cliente.alta,
        asesor: this.cliente.asesor
      };

      this.cliente.notas.push(nota);
      this.patchForm(this.cliente);
    }
  }

  initForm(){
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      status: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      telefono: new FormControl(""),
      inversion: new FormControl(""),
      observaciones: new FormControl(""),
      idioma: new FormControl("", Validators.required)
    });

    this.form2 = new FormGroup({
      asesor: new FormControl("", Validators.required)
    });
  }

  patchForm(cliente){
    this.form.patchValue({
      nombre: cliente.nombre,
      status: cliente.status,
      email: cliente.email,
      telefono: cliente.telefono,
      inversion: cliente.inversion,
      observaciones: cliente.observaciones,
      vinculo: cliente.vinculo,
      propiedad_interes: cliente.propiedad_interes,
      idioma: cliente.idioma
    });

    this.form2.patchValue({
      asesor: cliente.asesor
    })
  }

  submit(){
    let vars = {id:this.cliente.idcliente, cliente: this.form.value};
    
    this.ws.updateCliente(vars).subscribe(res => {
      if(res != 'fail'){
        const cliente = this.form.value;
        this.cliente.nombre = cliente.nombre;
        this.cliente.status = cliente.status;
        this.cliente.email = cliente.email;
        this.cliente.telefono = cliente.telefono;
        this.cliente.inversion = cliente.inversion;
        this.cliente.observaciones = cliente.observaciones;
        this.cliente.vinculo = cliente.vinculo;
        this.cliente.propiedad_interes = cliente.propiedad_interes;
        this.cliente.idioma = cliente.idioma;
        
        this.ws.notify('¡Cliente actualizado correctamente!', 'success', 1500);
        this.onUpdate.emit({isUpdated: true, cliente: this.cliente});
        $('.modal').modal('hide');
      } else{
        this.ws.notify('Hubo un problema al editar el cliente', 'error', 3000);
      }
    }, () => {
      this.ws.notify('Problema de conexión... Inténtalo de nuevo', 'warning', 3000);
    });
  }

  asignar(){
    let vars = {
      id:this.cliente.idcliente, 
      cliente: {
        asesor: this.form2.value.asesor,
        nombre: this.cliente.nombre,
        email: this.cliente.email,
        telefono: this.cliente.telefono,
        status: this.cliente.status,
        tags: this.cliente.tags,
        alta: this.cliente.alta,
        propiedad_interes: this.cliente.propiedad_interes
      }
    };
    
    this.ws.asignarCliente(vars).subscribe(res => {
      if(res != 'fail'){
        this.cliente.asesor = this.form2.value.asesor;
        this.onUpdate.emit({isUpdated: true, cliente: this.cliente});
        $('.modal').modal('hide');
      } else{
        this.ws.notify('Hubo un problema al asignar el cliente', 'error', 3000);
      }
    }, () => {
      this.ws.notify('Problema de conexión...', 'warning', 3000);
    });
  }

  isAdmin(email:string){
    const index = this.ws.admins.indexOf(email);
    if(index != -1){
      return 1;
    }
    return 0;
  }

  creaNota(data){
    this.cliente.notas.unshift(data);
  }

}
