import { Component, OnInit } from "@angular/core";
import { MysqlService } from "src/app/services/mysql.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";

@Component({
  selector: "app-create-price",
  templateUrl: "./create-price.component.html",
  styleUrls: ["./create-price.component.css"]
})
export class CreatePriceComponent implements OnInit {
  id: number;
  idproy: number;
  iddes: number;
  form: FormGroup;
  monedas = [];

  constructor(
    private ws: MysqlService,
    private routes: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
    this.routes.params.subscribe((params: Params) => {
      this.id = params.index;
      this.idproy = params.idp;
      this.iddes = params.id;
    });
    this.monedas = this.ws.monedas;
  }

  initForm() {
    this.form = new FormGroup({
      moneda: new FormControl("", Validators.required),
      precio_m2: new FormControl("", Validators.required)
    });
  }

  submit() {
    if (this.form.valid) {
      let vars = this.form.value;
      vars.idpropiedad = this.id;

      this.ws.createPrice(vars).subscribe(res => {
        if (res != "fail") {
          this.router.navigate([
            "/desarrolladores",
            this.iddes,
            "proyectos",
            this.idproy,
            "propiedades",
            this.id,
            "prices"
          ]);
        }
      });
    }
  }
}
