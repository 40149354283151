import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MysqlService } from '../../services/mysql.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-create-nota-cliente',
  templateUrl: './create-nota-cliente.component.html',
  styleUrls: ['./create-nota-cliente.component.css']
})
export class CreateNotaClienteComponent implements OnInit, OnChanges {
  form: FormGroup;
  user: any;
  @Input() cliente: any;
  @Output() crear = new EventEmitter;

  constructor( private ws: MysqlService, private fb: FirebaseService, private routes: ActivatedRoute, private router: Router ) { }

  ngOnInit() {
    this.fb.isLogged();
  }

  ngOnChanges(){
    this.initForm();
    if( this.cliente ){
    }
  }

  initForm(){
    this.form = new FormGroup({
      nota: new FormControl("", [Validators.required, Validators.maxLength(5000)])
    });
  }

  submit(){
    if( this.form.valid ){
      const fecha = new Date();
      const stringDate = fecha.toLocaleDateString();
      const fechaF = stringDate.split('/');
      const stringTime = fecha.toLocaleTimeString();
      const fechaE = fechaF[2] + '-' + fechaF[1] + '-' + fechaF[0] + ' ' + stringTime;

      let vars:any = {
        nota: {
          nota: this.form.value.nota,
          asesor: this.fb.user.email,
          idcliente: this.cliente.idcliente
        },
        lead: this.cliente
      };
      this.ws.createNota(vars).subscribe( res => {
        if(res == 'success'){
          const asesor = this.cliente.asesor.split('@')[0];
          vars.nota.fecha = fechaE;
          this.onCrear(vars.nota);
          this.ws.notify('¡' + asesor + ' fue notificado/a correctamente con esta nota!', 'success');
          this.form.reset();
          //this.router.navigate(['/clientes', this.id, 'edit']);
        }
      })
    }
  }

  onCrear(nota){
    this.crear.emit(nota);
  }

}
