import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MysqlService } from '../../services/mysql.service';
import { Router } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-create-cliente',
  templateUrl: './create-cliente.component.html',
  styleUrls: ['./create-cliente.component.css']
})
export class CreateClienteComponent implements OnInit {
  vinculos:any;
  status:any;
  idiomas:any;
  vinculo:string = "";

  form: FormGroup;
  user:any;

  constructor( private fb: FirebaseService, private ws: MysqlService, private router: Router ) {
    this.user = this.fb.user;
    this.vinculos = this.ws.vinculos;
    this.status = this.ws.status;
    this.idiomas = this.ws.idiomas;
  }

  ngOnInit() {
    this.initForm();
  }

  isAdmin(){
    const admins = ['mkt@evokeinternational.com.mx', 'direccion@evokeinternational.com.mx', 'sales@evokeinternational.com.mx', 'joaquin@evokeinternational.com.mx'];
    
    if(this.user && this.user.email){
      let index = admins.indexOf(this.user.email);
      return index > -1 ? true : false;
    }

    return false;
  }

  initForm(){
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      status: new FormControl("nuevo", Validators.required),
      email: new FormControl("", Validators.required),
      telefono: new FormControl(""),
      inversion: new FormControl("0"),
      observaciones: new FormControl(""),
      tags: new FormControl(""),
      asesor: new FormControl(""),
      vinculo: new FormControl("web", Validators.required),
      propiedad_interes: new FormControl(""),
      idioma: new FormControl("spa", Validators.required)
    });
  }

  submit(){
    let vars = this.form.value;
    vars.asesor = this.form.value.asesor == "" ? this.fb.user.email : this.form.value.asesor;
    
    this.ws.createCliente(vars).subscribe(res => {
      if(res == 'success'){
        this.router.navigate(['/clientes']);
      } else if(res == 'email') {
        this.ws.notify('Ya existe un lead con el correo ' + vars.email, 'error');
      } else{
        console.log('Error de creación: ', res);
      }
    })
  }

  verificaVinculo(){
    this.vinculo = this.form.value.vinculo;
    console.log('Cambia vínculo...');
  }

}
