import { Component, OnInit } from '@angular/core';
import { MysqlService } from 'src/app/services/mysql.service';
import { FirebaseService } from 'src/app/services/firebase.service';

declare var $:any;

@Component({
  selector: 'app-database-clientes',
  templateUrl: './database-clientes.component.html',
  styleUrls: ['./database-clientes.component.css']
})
export class DatabaseClientesComponent implements OnInit {
  clientes:any=[];

  constructor(private ws: MysqlService, private fb: FirebaseService ) { }

  async ngOnInit() {
    this.ws.notify('Cargando usuarios...');
    await this.ws.getDatabase(this.fb.user.email).subscribe((res:any) => {
      if(res != 'fail'){
        this.clientes = res;
        this.ws.clientes = res;
        this.ws.setDataTables();
      } else{
        this.clientes = [];
      }
    });
  }

  reestablecer(cliente){
    if(confirm('¿Estás seguro que deseas regresar este cliente al registro activo?')){
      const vars = {id: cliente.idcliente, cliente:{status:'sin_respuesta'}};
      this.ws.updateCliente(vars).subscribe( response => {
        if(response != 'fail'){
          this.ws.notify('¡Usuario reestablecido!', 'success');
          this.ws.getDatabase(this.fb.user.email).subscribe((res:any) => {
            if(res != 'fail'){
              this.clientes = res;
              this.ws.clientes = res;
              this.ws.setDataTables();
            } else{
              this.clientes = [];
            }
          })
        }
      })
    }
  }

  enviarBlacklist(cliente){
    if(confirm('¿Estás seguro que deseas enviar a la lista negra a este cliente?')){
      const vars = {id: cliente.idcliente, cliente:{status:'blacklist'}};
      this.ws.updateCliente(vars).subscribe( response => {
        if(response != 'fail'){
          this.ws.notify('¡Enviado a lista negra!', 'success');
          this.ws.getDatabase(this.fb.user.email).subscribe((res:any) => {
            if(res != 'fail'){
              this.clientes = res;
              this.ws.clientes = res;
              this.ws.setDataTables();
            } else{
              this.clientes = [];
            }
          })
        }
      })
    }
  }

  eliminar(cliente){
    if(confirm('¿Estás seguro que deseas eliminar completament este cliente?')){
      const vars = {id: cliente.idcliente};
      this.ws.removeCliente(vars).subscribe( response => {
        if(response != 'fail'){
          this.ws.notify('¡Usuario eliminado correctamente!', 'success');
          this.ws.getDatabase(this.fb.user.email).subscribe((res:any) => {
            if(res != 'fail'){
              this.clientes = res;
              this.ws.clientes = res;
              this.ws.setDataTables();
            } else{
              this.clientes = [];
            }
          })
        }
      })
    }
  }

  exportar(){
    $("#databaseTable").table2excel({
        exclude: ".excludeThisClass",
        name: "database",
        filename: "Leads_Database.xls", // do include extension
        preserveColors: false // set to true if you want background colors and font colors preserved
    });
  }

}
